body {
  background-color: #000000;
  overflow: hidden;
}

.App {
  font-family: sans-serif;
  text-align: center;
}

iframe {
  /*width: 34vw;*/
  /*height: 70vh;*/
  position: absolute;
  top: 56%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.bandai-logo img {
  position: absolute;
  top: 30px;
  right: 30px;
  width: 50px;
  z-index: 10;
}

.logo img {
  position: absolute;
  top: 30px;
  left: 30px;
  width: 150px;
  z-index: 10;
}

.bg img {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  z-index: 1;
  position: absolute;
  left: 0;
  pointer-events: none;
}
.cc {
  z-index: 50;
  position: absolute;
  bottom: 10px;
  left: calc(50vw - 160px);
  width: 350px;
  color: white;
  font-size: 10px;
  font-weight: bold;
}

@media only screen and (max-width: 600px) {
  .cc {
    z-index: 50;
    position: absolute;
    bottom: 10px;
    left: calc(50vw - 180px);
    width: 350px;
    color: white;
    font-size: 10px;
    font-weight: bold;
  }
}
